import React from 'react';
import styled, { css } from 'styled-components';
import type { NewsletterElementProps } from '../newsletter';
import { DEVICE_SIZE, mq } from '../../utils/mq';
import { LinkComponent } from '../../utils/component';

const Form = styled.form`
  background: var(--color-alt-4);
  border: solid 1px #ddd;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);
  padding: var(--spacer-m);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      padding: var(--spacer-s);
    `,
  )}
`;

const Title = styled.div`
  color: var(--text-color);
  font-family: var(--theme-typography-primary-font);
  font-size: 22px;
  margin: initial;
  padding: initial;
  font-weight: 700;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      text-align: center;
      color: var(--text-color);
    `,
  )}
`;

const InputGroup = styled.div`
  align-items: center;
  border: solid 2px var(--text-color-alt);
  border-radius: 6px;
  display: flex;
  font-size: 16px;
  gap: 7px;
  padding: 8px 15px;
`;

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
`;

const Submit = styled.button`
  background: var(--theme-palette-blue);
  border-radius: 6px;
  font-weight: bold;
  color: var(--theme-palette-white);
  text-decoration: none;
  gap: 10px;
  line-height: 1.62;
  transition: background-color 0.3s;
  margin: var(--spacer-s) 0;
  padding: var(--spacer-xs);

  &:hover {
    background: var(--theme-palette-dark-blue);
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      line-height: initial;
      font-size: 18px;
      margin: 0;
      padding: var(--spacer-s);
    `,
  )}
`;

const Description = styled.p`
  font-size: 18px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacer-s);

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: column-reverse;
    `,
  )}
`;

const Disclaimer = styled.p`
  font-size: 12px;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      text-align: center;
    `,
  )}
`;

const DisclaimerLinkLabel = styled.span`
  font-size: 12px;
  text-decoration: underline;
`;

const Success = styled.p`
  color: var(--theme-palette-green);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

const Error = styled.p`
  color: var(--theme-color-primary);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const UndernewsNewsletter = (params: NewsletterElementProps) => {
  const { submitCallback, success, error, email, labels } = params;
  const { title, description, placeholder, register, disclaimer, disclaimer_link_label } = labels;

  return (
    <Form onSubmit={(e: any) => submitCallback(e)}>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {success ? (
        <Success>{success}</Success>
      ) : (
        <>
          <InputGroup>
            <Input type="email" placeholder={placeholder} ref={email} required />
          </InputGroup>
          <Wrapper>
            <Disclaimer>
              {disclaimer}
              <LinkComponent href="https://www.clubic.com/politique_de_confidentialite">
                <DisclaimerLinkLabel>{disclaimer_link_label}</DisclaimerLinkLabel>
              </LinkComponent>
            </Disclaimer>
            <Submit type="submit">{register}</Submit>
          </Wrapper>
        </>
      )}
      {error && <Error>{error}</Error>}
    </Form>
  );
};
