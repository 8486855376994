import styled from 'styled-components';
import React from 'react';
import Clubic from '../../icons/clubic';
import type { NewsletterElementProps } from '../newsletter';

const Form = styled.form`
  background: var(--color-alt-4);
  border: solid 1px #ddd;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: var(--spacer-m) var(--spacer-s) var(--spacer-s);
`;

const Header = styled.div`
  margin: -70px auto 0;
  transform: rotate(-3deg);
`;

const Title = styled.div`
  color: var(--text-color);
  font-family: var(--theme-typography-primary-font);
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
`;

const InputGroup = styled.div`
  align-items: center;
  border: solid 2px var(--text-color-alt);
  border-radius: 6px;
  display: flex;
  font-size: 16px;
  gap: 7px;
  padding: 8px 15px;
`;

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
`;

const Submit = styled.button`
  background: var(--theme-palette-blue);
  border-radius: 6px;
  color: white;
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s;
  padding: 12px;

  &:hover {
    background: var(--theme-palette-dark-blue);
  }
`;

const Success = styled.p`
  color: var(--theme-palette-green);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

const Error = styled.p`
  color: var(--theme-color-primary);
  font-size: 14px;
  line-height: 22px;
  text-align: center;
`;

export const DefaultNewsletter = (params: NewsletterElementProps) => {
  const { submitCallback, success, error, email, labels } = params;
  const { title, placeholder, register } = labels;

  return (
    <Form onSubmit={(e: any) => submitCallback(e)}>
      <Header>
        <Clubic width={87} height={45} />
      </Header>
      <Title>{title}</Title>
      {success ? (
        <Success>{success}</Success>
      ) : (
        <>
          <InputGroup>
            <Input type="email" placeholder={placeholder} ref={email} required />
          </InputGroup>
          <Submit type="submit">{register}</Submit>
        </>
      )}
      {error && <Error>{error}</Error>}
    </Form>
  );
};
