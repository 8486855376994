import React, { ElementType } from 'react';
import styled from 'styled-components';
import { Cta, CtaProps } from '../cta';

type CtaWrapperProps = { alignment: string };
export type Props = CtaProps & CtaWrapperProps & { as?: ElementType; buttonSize: string };

const CtaButton = styled(Cta)`
  margin: var(--spacer-xs) 0 var(--spacer-s-fluid);
  padding: 8px 10px;
  font-size: 16px;

  &.size-large {
    padding: 10px 13px;
    font-size: 18px;
  }

  && {
    color: var(--theme-palette-white);
    text-decoration: none;
  }

  line-height: 1.62;

  background: var(--theme-palette-red);
  border: solid 2px var(--theme-palette-red);
  border-radius: 6px;
  font-weight: bold;
  position: relative;
  text-align: center;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background: var(--theme-palette-dark-red);
    border: solid 2px var(--theme-palette-dark-red);
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  &.align-right {
    justify-content: flex-end;
  }
  &.align-left {
    justify-content: flex-start;
  }
`;

const ArticleCta = ({ alignment, onClick, as, buttonSize, href, children }: React.PropsWithChildren<Props>) => {
  return (
    <CtaWrapper data-testid="cta-button-wrapper" className={`align-${alignment}`}>
      <CtaButton onClick={onClick} as={as} data-testid="cta-button" className={`size-${buttonSize}`} href={href}>
        {children}
      </CtaButton>
    </CtaWrapper>
  );
};

export default ArticleCta;
