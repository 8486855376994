import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';

export const Container = styled.div`
  grid-column: 2 / 3;

  &.mod-grid {
    display: grid;
  }

  &.mod-margin {
    margin: var(--spacer-l-to-default) 0 0;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: grid;
      grid-column: 1 / 10;
      grid-template-columns: 60px 240px 60px 60px 1fr 60px 60px 240px 60px;
    `,
  )}
`;

export const MainContent = styled.div`
  grid-column: 1 / 7;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      &.mod-mobile-after {
        order: 1;
      }
    `,
  )}
`;

export const MainBorderedContent = styled.div`
  grid-column: 2 / 3;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 2 / 6;
    `,
  )}
`;

export const FullContent = styled.div`
  grid-column: 1 / 7;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 1 / 10;
    `,
  )}
`;

export const FullContentRelative = styled(FullContent)`
  background: var(--theme-background-contrast-background-color);
  padding: 30px 0;
  position: relative;

  &.mod-spacer-xl {
    margin: var(--spacer-xl-to-s) 0;
  }

  &.mod-spacer-l {
    margin: var(--spacer-l-to-s) 0;
  }

  &.mod-spacer-top {
    margin: var(--spacer-fluid) 0 0;
  }

  &.mod-spacer-bottom {
    margin: 0 0 var(--spacer-fluid);
  }

  > * {
    position: relative;
    z-index: 1;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 0 var(--spacer-m-fluid);
    `,
  )}

  ${mq.gte(
    DEVICE_SIZE.X_LARGE,
    css`
      padding: var(--spacer) 0;
    `,
  )}
`;

export const Background = styled.div`
  background: var(--theme-background-contrast-background-color);
  bottom: 0;
  position: absolute !important;
  left: -15px;
  right: -15px;
  top: 0;
  z-index: 0 !important;
  max-width: 100vw;

  ${mq.gte(
    1180,
    css`
      right: calc(-1 * (100vw - 1180px) / 2);
      left: calc(-1 * (100vw - 1180px) / 2);
      max-width: initial;
    `,
  )}
`;

export const FullBorderedContent = styled.div`
  grid-column: 2 / 3;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      grid-column: 2 / 9;
    `,
  )}
`;

export const BoxContent = styled(FullContent)`
  background: var(--theme-background-contrast-background-color);
  padding: var(--spacer-s) var(--spacer-s) var(--spacer-s);
  left: -15px;
  position: relative;
  right: -15px;
  width: calc(100% + 30px);

  &.mod-top {
    border-radius: 20px;
    margin: 0 0 var(--spacer-s);

    ${mq.lte(
      DEVICE_SIZE.MEDIUM,
      css`
        border-radius: 10px;
      `,
    )}
  }
`;

export const SideContent = styled.aside`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 10;

  &.mod-gapped {
    gap: var(--spacer-m-fluid);
    margin: var(--spacer) 0;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: flex;
      flex-direction: column;
      grid-column: 8 / span 2;

      &.mod-gapped {
        margin: 0;
      }
    `,
  )}
`;

export const SideContentHalf = styled.div`
  flex-basis: 50%;
`;

export const SideContentSticky = styled.div`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      position: sticky;
      top: 20px;

      &.mod-lower {
        top: 80px;
      }

      &.mod-spacer {
        margin: 30px 0;
      }
    `,
  )};
`;

export const LargeSideContent = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
      grid-column: 7 / span 3;
    `,
  )}
`;

export const SideContentSpacer = styled.div`
  margin: 45px 0 0;

  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 30px 0 0;
    `,
  )}
`;

export const MobileSpacer = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      margin: var(--spacer) 0 0;
    `,
  )}
`;
