import React from 'react';
import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '../utils/mq';
import { useImageComponent, useLinkComponent } from '../utils/component';
import type { LinkProps } from '../atoms/types';
import { useTranslation } from '../utils/translation';

interface AuthorProp {
  id: string;
  title?: string;
  url?: string;
}

export interface PostItemProps {
  image: { imageId: string; alt: string };
  title: string;
  date: string;
  link: LinkProps;
  commentCount?: number;
  commentsNb?: number;
  tag?: string;
  authors?: AuthorProp[];
  sponsoredItem?: boolean;
  mainTag?: string;
}

const Wrapper = styled.article`
  display: flex;
`;

const Post = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  text-decoration: none;
  margin: 0 0 var(--spacer-m-fluid);

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      flex-direction: row;
    `,
  )}
`;

const LeftSide = styled.div`
  position: relative;
  width: 100%;

  picture {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }

  img {
    object-fit: cover;
  }

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      width: auto;

      picture {
        width: 240px;
      }

      img {
        border-radius: 6px;
        object-fit: initial;
      }
    `,
  )}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacer-s);
  justify-content: space-between;
  margin: var(--spacer-s) 0 0 0;

  ${mq.gte(
    DEVICE_SIZE.MEDIUM,
    css`
      margin: 0 0 0 var(--spacer);
    `,
  )}
`;

const Title = styled.p`
  color: var(--theme-color-title);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.38;
  transition: color 0.3s;

  &:hover {
    color: var(--theme-color-primary);
  }
`;

const Label = styled.div`
  align-self: start;
  //border: solid 1px var(--text-color);
  //border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.38;
  color: var(--text-color-alt);
  // padding: 3px var(--spacer-xs);
  text-transform: uppercase;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      line-height: 1.64;
    `,
  )}
`;

const Informations = styled.div`
  align-items: center;
  color: var(--theme-color-title);
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  font-size: 12px;
  gap: var(--spacer-xs);
  justify-content: flex-start;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 14px;
    `,
  )}
`;

const PublishDate = (props: React.HTMLAttributes<HTMLSpanElement>) => <span {...props} />;

const AuthorLabel = (props: React.HTMLAttributes<HTMLSpanElement>) => <span {...props} />;

const Comments = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const Sponsored = (props: React.HTMLAttributes<HTMLSpanElement>) => <span {...props} />;

export const PostItemLine: any = (props: PostItemProps) => {
  const { sponsoredItem, image, title, date, link, authors, commentsNb, commentCount, mainTag } = props;

  const ImageComponent = useImageComponent();
  const LinkComponent = useLinkComponent();
  const t = useTranslation();
  const correctCommentsCount = commentsNb || commentCount || 0;

  const imageProps = {
    ...image,
    mobileWidth: 280,
    mobileHeight: 157,
    desktopWidth: 240,
    desktopHeight: 135,
  };

  return (
    <Wrapper>
      <Post>
        <LeftSide>
          <LinkComponent {...link}>
            <ImageComponent {...imageProps} />
          </LinkComponent>
        </LeftSide>
        <Content>
          {mainTag ? <Label>{mainTag}</Label> : null}
          <LinkComponent {...link}>
            <Title data-testid="meta-label">{title}</Title>
          </LinkComponent>
          <Informations>
            <PublishDate data-testid="meta-date">{date}</PublishDate>
            {authors && (
              <AuthorLabel>
                {t('design.postItemLine.authorLabel')} <strong>{authors}</strong>
              </AuthorLabel>
            )}
            {correctCommentsCount > 0 && (
              <LinkComponent {...link}>
                <Comments>
                  ({correctCommentsCount} {t('design.postItemLine.commentLabel')}
                  {correctCommentsCount > 1 ? 's' : ''}
                  {sponsoredItem && ' - sponsorisé'})
                </Comments>
              </LinkComponent>
            )}
            {sponsoredItem && !commentCount && <Sponsored>(sponsorisé)</Sponsored>}
          </Informations>
        </Content>
      </Post>
    </Wrapper>
  );
};
