import React, { useRef, useState } from 'react';
import { useTranslation } from '../utils/translation';
import { UndernewsNewsletter } from './Newsletters/undernews';
import { DefaultNewsletter } from './Newsletters/default';

export type NewsletterElementProps = {
  submitCallback: (e: any) => void;
  success?: string;
  error?: string;
  email?: React.MutableRefObject<HTMLInputElement>;
  labels?: {
    title?: string;
    description?: string;
    placeholder?: string;
    register?: string;
    disclaimer?: string;
    disclaimer_link_label?: string;
  };
};

export const Newsletter = ({ layout }: any) => {
  const email = useRef<HTMLInputElement>();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const t = useTranslation();

  const registerNewsletter = (e: any) => {
    e.preventDefault();
    fetch('https://services.ownpage.fr/v1/subscribe/288e97776c3e41e8', {
      method: 'POST',
      body: JSON.stringify({
        form_key: '8a9d9acf76074efe',
        email: email.current?.value,
      }),
      headers: new Headers({
        'Content-Type': 'application/json; charset=UTF-8',
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data.success ? setSuccess(t('design.newsletter.success')) : setError(t('design.newsletter.error'));
      })
      .catch(() => {
        setError(t('design.newsletter.error'));
      });
  };

  const elementProps: NewsletterElementProps = {
    submitCallback: registerNewsletter,
    success,
    error,
    email,
    labels: {
      title: t('design.newsletter.title'),
      description: t('design.newsletter.description'),
      placeholder: t('design.newsletter.placeholder'),
      register: t('design.newsletter.register'),
      disclaimer: t('design.newsletter.disclaimer'),
      disclaimer_link_label: t('design.newsletter.disclaimer_link_label'),
    },
  };

  switch (layout) {
    case 'undernews':
      return <UndernewsNewsletter {...elementProps} />;
    default:
      return <DefaultNewsletter {...elementProps} />;
  }
};
